import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import List from "components/List";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "list"
    }}>{`List`}</h1>

    <Playground __position={0} __code={'<List type=\"unstyled\">\n  <List.Item>Lorem ipsum dolor sit amet</List.Item>\n  <List.Item>Consectetur adipiscing elit</List.Item>\n  <List.Item>\n    <List>\n      <List.Item>Phasellus iaculis neque</List.Item>\n      <List.Item>Purus sodales ultricies</List.Item>\n    </List>\n  </List.Item>\n  <List.Item>Faucibus porta lacus fringilla vel</List.Item>\n</List>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      List,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <List type="unstyled" mdxType="List">
    <List.Item>Lorem ipsum dolor sit amet</List.Item>
    <List.Item>Consectetur adipiscing elit</List.Item>
    <List.Item>
      <List mdxType="List">
        <List.Item>Phasellus iaculis neque</List.Item>
        <List.Item>Purus sodales ultricies</List.Item>
      </List>
    </List.Item>
    <List.Item>Faucibus porta lacus fringilla vel</List.Item>
  </List>
    </Playground>
    <Playground __position={1} __code={'<List type=\"unstyled\">\n  <List.ItemInline>Lorem ipsum dolor sit amet</List.ItemInline>\n  <List.ItemInline>Consectetur adipiscing elit</List.ItemInline>\n  <List.ItemInline>Integer molestie lorem at massa</List.ItemInline>\n</List>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      List,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <List type="unstyled" mdxType="List">
    <List.ItemInline>Lorem ipsum dolor sit amet</List.ItemInline>
    <List.ItemInline>Consectetur adipiscing elit</List.ItemInline>
    <List.ItemInline>Integer molestie lorem at massa</List.ItemInline>
  </List>
    </Playground>
    <Playground __position={2} __code={'<List.Group>\n  <List.GroupItem disabled tag=\"a\" href=\"#\">\n    Cras justo odio\n  </List.GroupItem>\n  <List.GroupItem tag=\"a\" href=\"#\">\n    Dapibus ac facilisis in\n  </List.GroupItem>\n  <List.GroupItem tag=\"a\" href=\"#\">\n    Morbi leo risus\n  </List.GroupItem>\n  <List.GroupItem tag=\"a\" href=\"#\">\n    Porta ac consectetur ac\n  </List.GroupItem>\n  <List.GroupItem tag=\"a\" href=\"#\">\n    Vestibulum at eros\n  </List.GroupItem>\n</List.Group>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      List,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <List.Group>
    <List.GroupItem disabled tag="a" href="#">Cras justo odio</List.GroupItem>
    <List.GroupItem tag="a" href="#">Dapibus ac facilisis in</List.GroupItem>
    <List.GroupItem tag="a" href="#">Morbi leo risus</List.GroupItem>
    <List.GroupItem tag="a" href="#">Porta ac consectetur ac</List.GroupItem>
    <List.GroupItem tag="a" href="#">Vestibulum at eros</List.GroupItem>
  </List.Group>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/list/" target="_blank">Reactstrap List</a> | <a href="https://reactstrap.github.io/components/listgroup/" target="_blank">Reactstrap List Group</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      